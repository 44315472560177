import {filter, forEach, intersection, slice, sortBy,prop} from "ramda";

export const isGranted = (user,page) => {
    if(user === null){
        return false
    }
    if(!user.hasOwnProperty('roles')){
        return false
    }
    if ((page === 'PAGE_STOCK' || page === 'PAGE_DEMANDE_REAPPRO_STOCK') && !user.hasStock) {
        return false;
    }

    let roles = ['ROLE_FULL']
    if(process.env.hasOwnProperty('REACT_APP_' + page)){
        if(process.env['REACT_APP_' + page] === 'ALL'){
            return true
        }
        roles = process.env['REACT_APP_' + page].split(',')
    }

    return intersection(user.roles,roles).length > 0
}

export const getFileInfo = (filename) => {
    let listMonth = {
        janv: 'janvier',
        fev: 'février',
        mars: 'mars',
        avr: 'avril',
        mai: 'mai',
        juin: 'juin',
        juil: 'juillet',
        aout: 'août',
        sept: 'septembre',
        oct: 'octobre',
        nov: 'novembre',
        dec: 'décembre'
    }
    // Les noms de fichier sont en minuscule séparés par des _ et avec innoclair, puis mois et annee à la fin
    let parts = filename.split('_')

    if (parts[0].search(/^\d{3}$/) >= 0) {
        parts.splice(0,1);
    }
    let nbParts = parts.length
    if(parts < 3){
        return {
            filename: parts.join(' '),
            initFilename: filename,
            mois: null,
            annee: null
        }
    }

    let month = parts[nbParts-2]
    let year = parts[nbParts-1]
    let nbPartsToForgot = parts[nbParts-3] === 'innoclair' ? -3: -2

    if(listMonth.hasOwnProperty(month) && year.length === 4){
        return {
            filename: slice(0,nbPartsToForgot,parts).join(' '),
            initFilename: filename,
            mois: listMonth[month],
            annee: year
        }
    } else {
        return {
            filename: parts.join(' '),
            initFilename: filename,
            mois: null,
            annee: null
        }
    }
}

export const objectValuesAlternative = (obj) => {
    let keys = Object.keys(obj)
    let nbOccur = keys.length
    if(nbOccur == 0){
        return []
    }
    let result = []
    keys.forEach(cle => {
        result.push(obj[cle])
    })
    return result
}

export const flattenListCustomer = (indexedObjects) => {
    let objectArray = objectValuesAlternative(indexedObjects);
    let result = [];
    objectArray.map(obj => {
        result.push({
            label : obj.CardName,
            id : obj.InternalCode,
            forSorting: obj.CardType === 'L' ? (obj.NombreDevis === 0 ? '1':'2'):'3',
            category: obj.CardType === 'L' ?
                obj.NombreDevis === 0 ? 'Opportunité':
                    'Opportunité avec devis'
                    : 'Client'
        });
    })
    const sortByType = sortBy(prop('forSorting'));
    return sortByType(result);
}

export const flattenListItem = (indexedObjects) => {
    let objectArray = objectValuesAlternative(indexedObjects);
    let result = [];
    objectArray.map(obj => {
        result.push({
            label : '['+obj.itemCode+'] '+obj.itemName,
            id : obj.itemCode
        });
    })
    return result;
}

export const flattenListCustomerMes = (indexedObjects) => {
    let objectArray = objectValuesAlternative(indexedObjects);
    let result = [];
    let newLabel ='';
    objectArray.map(obj => {
        newLabel = obj.Identite;
        if (result.find(o =>o.label === obj.Identite)){
            newLabel = obj.Identite+' ('+obj.CardCode+')'
        }
        result.push({
            label : newLabel,
            id : obj.InternalCode
        });
    })
    return result;
}

export const flattenListAdressesMes = (indexedObjects) => {
    let objectArray = objectValuesAlternative(indexedObjects);
    let result = [];
    objectArray.map(obj => {
        result.push(
            obj.AddressName,
        );
    })
    return result;
}

// export const flattenListModePaiement = (indexedObjects) => {
//     let objectArray = objectValuesAlternative(indexedObjects)
//     let result = []
//     objectArray.map(obj => {
//         result.push({
//             label: obj.paymentDescription,
//             id: obj.paymentMethodCode
//         })
//     })
//     return result
// }

export const flattenListConditionPaiement = (indexedObjects) => {
    let objectArray = objectValuesAlternative(indexedObjects)
    let result = []
    objectArray.map(obj => {
        result.push({
            label: obj.paymentTermsGroupName,
            id: obj.groupNumber
        })
    })
    return result
}

export const flattenListWarehouse = (indexedObjects) => {
    let objectArray = objectValuesAlternative(indexedObjects)
    let result = []
    objectArray.map(obj => {
        result.push({
            label: obj.Warehouse,
            id: obj.WarehouseCode
        })
    })
    return result
}

export const flattenListShipAddress = (indexedObjects) => {
    let objectArray = objectValuesAlternative(indexedObjects);
    let result = [];
    objectArray.map(obj => {
        result.push({
            label : obj.Street + ' ' + obj.ZipCode + ' ' + obj.City + ' ('+obj.AddressName+')',
            id : obj.AddressName
        });
    })
    return result;
}

export const flattenListWarehouses = (indexedObjects) => {
    let objectArray = objectValuesAlternative(indexedObjects);
    let result = [];
    objectArray.map(obj => {
        result.push({
            label : obj.WarehouseName,
            id : obj.WarehouseCode
        });
    })
    return result;
}

export const flattenListEquipments = (indexedObjects) => {
    let objectArray = objectValuesAlternative(indexedObjects);
    let result = [];
    objectArray.map(obj => {
        result.push({
            label : obj.label,
            id : obj.serialNumber
        });
    })
    return result;
}

export const findFirstBy = (arr, field, value) => {
    let result = null
    arr.map((el,index) => {
        if(el[field] === value){
            result = el;
        }
    })
    return result
}

export const filterQuotation = (listeBrute, cardCode) => {
    if(listeBrute === null){
        return null
    }
    let listeFinale = []
    listeBrute.map(el => {
        if(el.CardCode === cardCode){
            listeFinale.push(el)
        }
    })
    return listeFinale
}

export const getCardMetaData = (columns) =>{
    let result = null;
    if(columns !== null){
        columns.map((column) => {
            // console.log(column)
            if(column.hasOwnProperty('card')){
                if(result === null){
                    result = {}
                }
                if(column?.link){
                    result['link'] = column.link
                }
                result[column.card] = column.id
                if(column.card === 'title'){
                    result['label'] = column.label
                }
                if(column.card === 'action'){
                    result['action-description'] = column
                }
            }
        })
    }
    return result
}

export const filterSearch = (search,ligne) => {
    let freeSearch = new RegExp(search,'i')
    return(freeSearch.test(ligne.cardName) ||  freeSearch.test(ligne.firstName) || freeSearch.test(ligne.lastName))
}

export const flattenListPrestataires = (list) => {
    let result = [];
    Object.keys(list).map(index => {
        result.push({
            label :(list[index].Remarks1 ? list[index].Remarks1 +' - ' : '')+
                (!!list[index].Titre ? list[index].Titre +' ' : '')+
                (!!list[index].FirstName ? list[index].FirstName : '')+' '+
                list[index].LastName+
                (!!list[index].E_Mail ? ' ('+ list[index].E_Mail+')' : ''),
            id : list[index].InternalCode
        });
    })
    return result;
}

export const reformatDate = (string) => {
    let datePosition = string.search(/\d{4}-[01]\d-[0-3]\d/)
    while (datePosition !== -1) {
        string = string.replace(/\d{4}-[01]\d-[0-3]\d/,
            string.substring(datePosition + 8, datePosition + 10) + '/' +
            string.substring(datePosition + 5, datePosition + 7) + '/' +
            string.substring(datePosition, datePosition + 4)
        )
        datePosition = string.search(/\d{4}-[01]\d-[0-3]\d/)
    }
    return string
}