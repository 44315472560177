import React, {useEffect, useState} from "react"
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {connect} from "react-redux";
import {
    Alert,
    AlertTitle,
    Avatar,
    Box,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    Grid, Link,
    Paper,
    Popper
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import {DeleteForever, Euro} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {CancelButton, StandardButton, ValidateButton} from "../Buttons";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {flattenListEquipments, flattenListItem, flattenListShipAddress} from "../Utils";
import {assoc, assocPath, clone} from "ramda";
import {initDocument, setDocument} from "./DevisForm";
import {useLocation, useNavigate} from "react-router-dom";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Drawer from "@mui/material/Drawer";
import AddressForm from "./AddressForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

export const useStyles = makeStyles(theme => ({
    thinTextField:{
        '& input':{
            padding: 4
        },
        '& .MuiOutlinedInput-root .MuiAutocomplete-input':{
            padding: '0 !important'
        }
    }
}))

const CommandeForm = ({
    user = null,
    userImpersonator = null,
    dispatch
}) => {
    const classes = useStyles()
    const navigate = useNavigate()

    const [document, setDocument] = useState({
        numAtCard: null,
        shipToCode: null,
        docDueDate: null,
        commentaire: null,
        lines: [
            {
                lineNum: null,
                itemCode : '',
                itemName: '',
                quantity : null,
                price : null,
                serialNumber : null
            }
        ],
        total: null,
    })
    const [listItem, setListItem] = useState(null)
    const [flattenedItem, setFlattenedItem] = useState([])
    const [listEquipments, setListEquipments] = useState(null)
    const [flattenedEquipments, setFlattenedEquipments] = useState([])
    const [listShipAddress, setListShipAddress] = useState(null)
    const [listFlattenShipAddress, setFlattenListShipAddress] = useState([])
    const [orderCreating, setOrderCreating] = useState(false)
    const [cgvAccepted, setCgvAccepted] = useState(false)

    const location = useLocation()
    const uri = location.pathname.substring(1)

    const handleChangeDocument = (property,value) => {
        setDocument(assoc(property, value, document))
    }

    const handleChangeLine = (index, property, value) => {
        let clonedDoc = clone(document)

        clonedDoc = assocPath(['lines',index, property], value, clonedDoc)
        if(property === 'itemCode') {
            clonedDoc = assocPath(['lines',index, 'price'], listItem[value].price, clonedDoc)
            clonedDoc = assocPath(['lines',index, 'quantity'], 1, clonedDoc)
        }
        clonedDoc.total = calculTotal(clonedDoc)
        clonedDoc.totalQte = calculQte(clonedDoc)
        setDocument(clonedDoc)
    }

    const addLine = () => {
        let clonedDoc = clone(document);
        clonedDoc.lines.push({
            'itemCode' : '',
            'itemName' : '',
            'quantity' : null,
            'price' : null,
            'serialNumber' : null,
            'lineNum': null
        });
        clonedDoc.total = calculTotal(clonedDoc)
        clonedDoc.totalQte = calculQte(clonedDoc)
        setDocument(clonedDoc)
    }

    const removeLine = (index) => {
        let clonedDoc = clone(document);
        clonedDoc['lines'].splice(index, 1);
        clonedDoc.total = calculTotal(clonedDoc)
        clonedDoc.totalQte = calculQte(clonedDoc)
        setDocument(clonedDoc)
    }

    useEffect(() => {
        fetchData()
    },[])

    const fetchData = (addressesOnly = false) => {
        dispatch(setMessageInformation(null))
        const apiUrl = uri === 'create-commande' ? '/api/order/get-all-data' : '/api/order/get-all-data-sav'
        apiGet(apiUrl)
            .then(response => {
                if(response.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: response.message
                    }))
                } else {
                    setListShipAddress(response.addresses)
                    setFlattenListShipAddress(flattenListShipAddress(response.addresses))
                    if (!addressesOnly) {
                        setListItem(response.items)
                        setFlattenedItem(flattenListItem(response.items))
                        if (uri === 'create-commande-sav'){
                            setListEquipments(response.equipments)
                            setFlattenedEquipments(flattenListEquipments(response.equipments))
                        }
                    }
                }
            })
    }

    const handleValidateOrder = (event) => {
        dispatch(setMessageInformation(null))
        if (validateOrder()) {
            setOrderCreating(true)
            const apiUrl = uri === 'create-commande' ? '/api/order/create' : '/api/order/create-sav'
            apiPost(apiUrl,document)
                .then(response => {
                    if(response.status === 'error'){
                        dispatch(setMessageInformation({
                            severity: 'error',
                            title: 'Une erreur est survenue',
                            content: response.message
                        }))
                    } else {
                        navigate(uri === 'create-commande' ? '/commande' : '/commande-sav')
                    }
                })
                .finally(() => {
                    setOrderCreating(false)
                })
        }
    }

    const validateOrder = () => {
        if (!cgvAccepted) {
            dispatch(setMessageInformation({
                severity: 'error',
                title: 'Une erreur est survenue',
                content: 'Vous devez accepter les conditions générales de vente.'
            }))
            return false
        }
        if (uri === 'create-commande-sav') {
            if (document.lines.some((line) => {
                return (line?.itemCode && !line?.serialNumber)
            })){
                dispatch(setMessageInformation({
                    severity: 'error',
                    title: 'Une erreur est survenue',
                    content: 'Chaque article doit être associé à une station.'
                }))
                return false
            }
        }
        return true
    }

    const calculTotal = (clonedDoc) => {
        let result = 0
        clonedDoc.lines.map((line, index) => {
            if(line?.quantity && line?.price){
                result += line.quantity * line.price
            }
        })
        result=Math.round(result*100)/100
        return result
    }

    const calculQte = (clonedDoc) => {
        let result = 0
        clonedDoc.lines.map((line, index) => {
            result += parseInt(line.quantity, 10)
        })
        return result
    }

    return (
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title={"Créer une commande" + (uri === 'create-commande-sav' ? ' sous garantie' : '')}
            page_active={uri.substring(7)}
            dispatch={dispatch}
        >
            <Container
                sx={{
                    marginTop : '10px'
                }}
            >
                <Grid container direction="row" spacing={1} style={{marginTop: 20, flex: 1, alignItems: 'flex-end'}}>
                    <Grid item xs={12}>
                        <TextField
                            label="Référence commande"
                            variant="standard"
                            value={document.numAtCard ? document.numAtCard : ''}
                            fullWidth={true}
                            className={classes.thinTextField}
                            onChange={(e) => {
                                handleChangeDocument(
                                    'numAtCard',
                                    e.target.value
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            freeSolo={false}
                            options={listFlattenShipAddress}
                            renderInput={
                                (params) =>
                                    <TextField {...params} variant="standard" label="Adresse de livraison" />
                            }
                            value={
                                document?.shipToCode && listShipAddress && listShipAddress[document?.shipToCode] ?
                                    {
                                        label: listShipAddress[document.shipToCode].Street + ' ' + listShipAddress[document.shipToCode].ZipCode + ' ' + listShipAddress[document.shipToCode].City + ' ('+document.shipToCode+')',
                                        id: document.shipToCode
                                    }
                                    :
                                    null
                            }
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(e, newValue) => {
                                handleChangeDocument(
                                    'shipToCode',
                                    newValue.id
                                )
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={'caption'} sx={{color:'DimGray', fontStyle:'italic'}}>
                            contacter innoclair si l'adresse que vous souhaitez utiliser est manquante
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{marginTop : '20px'}}>
                        <TextField
                            label="Instructions logistiques"
                            multiline
                            fullWidth
                            value={document?.commentaire ? document.commentaire : ''}
                            onChange={(e) =>
                                handleChangeDocument('commentaire', e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                            <DatePicker
                                fullWidth={true}
                                label="Date de livraison souhaitée"
                                onChange={(e) => {
                                    handleChangeDocument(
                                        'docDueDate',
                                        e?.format('YYYY-MM-DD')
                                    )
                                }}
                                value={document.docDueDate ? document.docDueDate : null}
                                renderInput={(params) =>
                                    <TextField {...params} fullWidth={true}/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Typography variant={'h5'} style={{marginTop:20, marginBottom: 10}}>
                    Articles
                </Typography>

                <Paper style={{padding: 20, borderLeft: 'solid 5px #042C48'}}>
                    {
                        document.lines.map((line, index) => {

                            let itemValue = (line.itemCode !== '' && listItem !== null && listItem.hasOwnProperty(line.itemCode)) ?
                                {
                                    label: listItem[line.itemCode].itemName,
                                    id: line.itemCode
                                }:
                                ''

                            let serialNumberValue = (line.serialNumber !== '' && listEquipments !== null && listEquipments.hasOwnProperty(line.serialNumber)) ?
                                {
                                    label: listEquipments[line.serialNumber].label,
                                    id: line.serialNumber
                                }:
                                ''

                            return(
                                <Grid container direction="row" spacing={1} id={index} key={index} style={{marginBottom:'1.5em'}}>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            disablePortal
                                            options={flattenedItem}
                                            renderInput={(params) => <TextField {...params} variant="standard" label="Article" />}
                                            PopperComponent={CustomPopper}
                                            value={itemValue}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            onChange={(e, newValue) => {
                                                handleChangeLine(
                                                    index,
                                                    'itemCode',
                                                    newValue.id
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={2}>
                                        <TextField
                                            label="Quantité"
                                            type="number"
                                            variant="standard"
                                            value={line.quantity ? line.quantity : ''}
                                            onChange={(e) => {
                                                handleChangeLine(
                                                    index,
                                                    'quantity',
                                                    e.target.value
                                                )
                                            }}
                                            inputProps={{style: {textAlign: 'center'}}}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    {
                                        uri === 'create-commande' ?
                                            <Grid item xs={6} md={3}>
                                                <TextField
                                                    label="Prix"
                                                    type='number'
                                                    variant="standard"
                                                    value={line.price ? line.price : ''}
                                                    fullWidth={true}
                                                    inputProps={{style: {textAlign: 'right'}}}
                                                    disabled={true}
                                                    InputProps={{
                                                        endAdornment: <Avatar sx={{ width: 24, height: 24, backgroundColor: '#042C48', marginLeft: 1 }}><Euro sx={{fontSize: 14}} /></Avatar>
                                                    }}
                                                />
                                            </Grid>
                                        : null
                                    }
                                    {
                                        uri === 'create-commande-sav' ?
                                            <Grid item xs={6} md={3}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={flattenedEquipments}
                                                    renderInput={(params) =>
                                                        <TextField {...params} variant="standard" label="Station" />
                                                    }
                                                    PopperComponent={CustomPopper}
                                                    value={serialNumberValue}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    onChange={(e, newValue) => {
                                                        handleChangeLine(
                                                            index,
                                                            'serialNumber',
                                                            newValue.id
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        : null
                                    }
                                    <Grid item xs={1}>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => {
                                                removeLine(index);
                                            }}
                                            fullWidth={true}
                                            style={{maxHeight: 32, marginTop: 12}}
                                        >
                                            <DeleteForever />
                                        </Button>
                                    </Grid>

                                </Grid>
                            )
                        })
                    }
                    <Grid container direction="row" spacing={1} style={{marginTop: 20}}>
                        <Grid item xs={12}>
                            <StandardButton
                                variant="outlined"
                                onClick={() => {
                                    addLine();
                                }}
                            >
                                Ajouter une ligne d'article
                            </StandardButton>
                        </Grid>
                    </Grid>

                    {
                        uri === 'create-commande' ?
                            <>
                                <Divider sx={{marginTop : '1.5em', marginBottom : '1em'}}/>
                                <Grid container spacing={1} alignItems={'center'} justifyContent={'end'}>
                                    <Grid item xs={12} md={'auto'}>
                                        <Typography sx={{paddingTop:'1em', paddingRight:'1em'}}>
                                            Total*
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} md={'auto'} style={{textAlign: 'right'}}>
                                        <TextField
                                            disabled={true}
                                            label="Quantité"
                                            variant="standard"
                                            type='number'
                                            value={document.totalQte}
                                            inputProps={{style: {textAlign: 'right'}}}
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={'auto'} style={{textAlign: 'right'}}>
                                        <TextField
                                            disabled={true}
                                            label='Prix HT'
                                            variant="standard"
                                            type='number'
                                            value={document.total}
                                            inputProps={{style: {textAlign: 'right'}}}
                                            InputProps={{
                                                endAdornment: <Avatar sx={{ width: 24, height: 24, backgroundColor: '#042C48', marginLeft: 1 }}><Euro sx={{fontSize: 14}} /></Avatar>
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        : null
                    }
                </Paper>
                    <Grid
                        container
                        sx={{marginTop : '1em'}}
                        alignItems={'center'}
                    >
                        <Grid item xs={'auto'}>
                            <FormControlLabel
                                label={'Je reconnais avoir lu et accepté sans réserve '}
                                control={
                                    <Checkbox
                                        checked={cgvAccepted}
                                        onChange={e => {
                                            setCgvAccepted(e.target.checked)
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs>
                            <Link
                                href={process.env.REACT_APP_API_URL+'/public/get-attachment/CGV.pdf/inline'}
                                target={"_blank"}
                            >
                                les conditions générales de vente
                            </Link>
                        </Grid>
                    </Grid>
                <Grid container direction="row" spacing={3} sx={{marginTop:0}}>
                    <Grid item xs={6}>
                        <CancelButton
                            fullWidth={true}
                            variant="contained"
                            color="info"
                            onMouseUp={(e) => {
                                navigate('/commande')
                            }}>
                            Annuler
                        </CancelButton>
                    </Grid>
                    <Grid item xs={6}>
                        {
                            orderCreating
                                ?
                                <ValidateButton
                                    fullWidth={true}
                                    variant="contained"
                                    color="success"
                                    disabled
                                >
                                    Création en cours ...
                                </ValidateButton>
                                :
                                <ValidateButton
                                    fullWidth={true}
                                    variant="contained"
                                    color="success"
                                    onClick={handleValidateOrder}
                                >
                                    Créer
                                </ValidateButton>
                        }
                    </Grid>
                </Grid>

                {
                    uri === 'create-commande' ?
                        <Typography
                            sx={{
                                marginTop:'1em',
                                fontStyle:'italic',
                                color:'gray'
                            }}
                        >
                            * Pour toute commande de matériel inférieure à 1 000€, des frais de port seront appliqués.
                        </Typography>
                    : null
                }
                <Box sx={{marginBottom:'1em'}}></Box>
            </Container>
        </PageSkeleton>
    )
}

const CustomPopper = function (props) {
    return <Popper {...props} style={{width: "fit-content"}} placement="bottom-start" />;
};

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        pageLoading: state.pageLoaderReducer.pageLoading
    }),
)(CommandeForm)
