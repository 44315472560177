import React, {useEffect, useState} from "react";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {connect} from "react-redux";
import Container from "@mui/material/Container";
import {createSlice} from "@reduxjs/toolkit";
import {assoc, clone} from "ramda";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {Alert, AlertTitle, Paper} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {CancelButton, ValidateButton} from "../Buttons";
import {useStyles} from "./DevisForm";
import {useLocation} from "react-router-dom";

const AddressForm = ({
    address,
    setAddress,
    setOpenAddress,
    addressAdded,
}) => {
    const [citySearching, setCitySearching] = useState(false)
    const [listeVille, setListeVille] = useState([]);
    const [error,setError] = useState('')
    const [creating,setCreating] = useState(false)
    const [geoApiFailed, setGeoApiFailed] = useState(false)

    const classes = useStyles()

    const location = useLocation()
    const uri = location.pathname.substring(1)

    const handleSetZipCode = (e) => {
        let clonedAddress = clone(address)
        clonedAddress['zipCode'] = e.target.value
        clonedAddress['city'] = ''
        setAddress(clonedAddress)
        if(e.target.value.length === 5){
            setCitySearching(true)
            setListeVille([])
            apiPost('/anonymous-api/get-city',{zipCode: e.target.value})
                .then(data => {
                    if(data.status === 'success'){
                        setGeoApiFailed(false)
                        setListeVille(data.data)
                    } else {
                        if (data.message === 'api error') {
                            setGeoApiFailed(true)
                        } else {
                            console.log(data.message)
                        }
                    }
                })
                .finally(() => {
                    setCitySearching(false)
                })
        }
    }

    const createAddress = () => {
        setError('')
        const errorForm = validateAddress()
        if(errorForm !== ''){
            setError(errorForm)
        } else {
            setCreating(true);
            apiPost(address.apiUrl, address)
                .then(data => {
                    if (data.status === 'error') {
                        setError(data.message);
                    } else {
                        addressAdded()
                        setOpenAddress(false)
                    }
                })
                .finally(() => {
                    setCreating(false);
                })
        }
    }
    const validateAddress = () => {
        if (!address?.label){
            return 'Le champ libellé doit être renseigné.'
        }
        if (!address?.street){
            return 'Le champ adresse doit être renseigné.'
        }
        if (!address?.zipCode){
            return 'Le champ code postal doit être renseigné.'
        }
        if (!address?.city){
            return 'Le champ ville doit être renseigné.'
        }
        return ''
    }
    return(
        <Container maxWidth="md">
            <Grid
                container
                sx={{marginTop:2}}
                direction='column'
                spacing={1}
            >
                {
                    error !== ''
                        ?
                        <Alert severity="error" sx={{overflowWrap : 'anywhere'}}>
                            <AlertTitle>Une erreur est survenue</AlertTitle>
                            {error}
                        </Alert>:
                        null
                }
                <Paper sx={{padding : '1em'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                disabled={creating || uri.startsWith('update-lead') }
                                fullWidth={true}
                                label="Libellé"
                                variant="standard"
                                id="Label"
                                required={true}
                                value={address?.label ? address.label : ''}
                                onChange={(e) => {
                                    setAddress(assoc('label', e.target.value, address))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={creating}
                                fullWidth={true}
                                label="Adresse"
                                variant="standard"
                                id="Street"
                                required={true}
                                value={address?.street ? address.street : ''}
                                onChange={(e) => {
                                    setAddress(assoc('street', e.target.value, address))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={creating}
                                fullWidth={true}
                                label="Complément d'adresse"
                                variant="standard"
                                id="Block"
                                value={address?.block ? address.block : ''}
                                onChange={(e) => {
                                    setAddress(assoc('block', e.target.value, address))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={creating}
                                fullWidth={true}
                                label="Code postal"
                                variant="standard"
                                id="ZipCode"
                                required={true}
                                value={address?.zipCode ? address.zipCode : ''}
                                onChange={(e) => {
                                    handleSetZipCode(e)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                freeSolo={geoApiFailed}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        disabled={creating}
                                        variant="standard"
                                        label="Ville"
                                        required={true}
                                    />
                                }
                                disabled={creating}
                                options={listeVille}
                                id="City"
                                value={address?.city ? address.city : ''}
                                className={classes.thinTextField}
                                noOptionsText={
                                    citySearching ?
                                        'Recherche en cours ...':
                                        'Veuillez renseigner un code postal valide'
                                }
                                onChange={(e, newValue) => {
                                    setAddress(assoc('city', newValue.id, address))
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Grid container spacing={3} style={{marginBottom: 20}}>
                    <Grid item xs={6} style={{marginTop: 20}}>
                        <CancelButton
                            disabled={creating}
                            onClick={(e) => {
                                setAddress({})
                                setOpenAddress(false)
                            }}
                            fullWidth={true}
                            style={{color: 'white'}}
                        >
                            Annuler
                        </CancelButton>
                    </Grid>
                    <Grid item xs={6} style={{marginTop: 20}}>
                        {
                            creating ?
                                <CancelButton
                                    disabled={creating}
                                    variant="contained"
                                    fullWidth={true}
                                    disabled
                                >
                                    Création en cours ...
                                </CancelButton>
                            :
                                <ValidateButton
                                    variant="contained"
                                    fullWidth={true}
                                    onClick={(event) => {
                                        createAddress()
                                    }}
                                >
                                    Valider
                                </ValidateButton>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
    }),
)(AddressForm)
