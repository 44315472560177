import React, {useEffect, useState} from "react";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip"
import {connect} from "react-redux";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {createSlice} from "@reduxjs/toolkit";
import {clone, empty, isEmpty} from "ramda";
import {setDocument, setLine, useStyles} from "./DevisForm";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {Alert, AlertTitle, Collapse, FormControl, MenuItem, Paper, Select, Switch} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {CancelButton, ValidateButton} from "../Buttons";
import {changePageLoadingState} from "../PageLoader";
import {findFirstBy, objectValuesAlternative} from "../Utils";
import {useLocation, useNavigate} from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/fr';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {light,solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {setMessageInformation} from "../PageSkeleton";

export const initialState = {
    partner : {
        'FirstName': null,
        'LastName' : null,
        'CardName' : null,
        'GroupCode' : '',
        'Email' : null,
        'MobilePhone' : null,
        'StreetB' : null,
        'BlockB' : null,
        'ZipCodeB' : null,
        'CityB' : null,
        'StreetS' : null,
        'BlockS' : null,
        'ZipCodeS' : null,
        'CityS' : null,
        'Object': '',
        'Comment': '',
        'Origine': null,
        'SlpCode': -1,
        'Title': '',
        'DateChantier': '',
    },
};

export const partnerSlice = createSlice({
    name: 'partner',
    initialState: initialState,
    reducers: {
        setPartner: (state, action) => {
            let clonedPartner = clone(state.partner);
            clonedPartner[action.payload.property] = action.payload.value;
            if(action.payload.property === 'CityB'  && action.payload.hasOwnProperty('listeVilleB')){

                let selectedVille = action.payload.listeVilleB.filter(ville => {
                    return ville.id === action.payload.value
                })
                if(selectedVille.length === 1 && selectedVille[0].hasOwnProperty('slpCode') && !empty((selectedVille[0].slpCode))){
                    clonedPartner.SlpCode = selectedVille[0].slpCode
                }
            }
            return {
                ...state,
                partner: clonedPartner
            }
        },
        initPartner: (state, action) => {
            return {
                ...state,
                partner: {
                    'FirstName': null,
                    'LastName' : null,
                    'CardName' : null,
                    'GroupCode' : '',
                    'Email' : null,
                    'MobilePhone' : null,
                    'StreetB' : null,
                    'BlockB' : null,
                    'ZipCodeB' : null,
                    'CityB' : null,
                    'StreetS' : null,
                    'BlockS' : null,
                    'ZipCodeS' : null,
                    'CityS' : null,
                    'Object': '',
                    'Comment': '',
                    'Origine': null,
                    'SlpCode': -1,
                    'Title': '',
                    'DateChantier': '',
                }
            }
        }
    }
});

export const {actions, reducer} = partnerSlice;

export const {
    setPartner,
    initPartner
} = actions;

const PartnerForm = ({
    partner = null,
    setListCustomer= null,
    setFlattenedCustomer= null,
    flattenListItem= null,
    openPartner = null,
    setOpenPartner = null,
    listModePaiement = null,
    listConditionPaiement = null,
    customerAdded = null,
    poseurAdded = null,
    beAdded = null,
    spancAdded = null,
    dispatch
}) => {
    const [partnerCreating,setPartnerCreating] = useState(false)
    const [listeVilleB, setListeVilleB] = useState([]);
    const [listeVilleS, setListeVilleS] = useState([]);
    const [citySearching, setCitySearching] = useState(false)
    const [differenteAddress,setDifferenteAddress] = useState(false)
    const [listeCommercial, setListeCommercial] = useState([])
    const [listeCivilite, setListeCivilite] = useState([])
    const [geoApiFailed, setGeoApiFailed] = useState(false)

    const classes = useStyles()

    const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

    const createPartner = (saisieLeadAgent, saisieWebManager, navigate) => {
        dispatch(setMessageInformation(null))
        const errorForm = validatePartner()
        if(errorForm !== ''){
            dispatch(setMessageInformation({
                severity: 'error',
                title: 'Une erreur est survenue',
                content: errorForm
            }))
        } else {
            setPartnerCreating(true);
            let clonedPartner = clone(partner)
            switch(openPartner){
                case 'poseur' :
                    clonedPartner.GroupCode = 'POSE'
                    clonedPartner.CardName = '-'+clonedPartner.CardName
                    break
                case 'be' :
                    clonedPartner.GroupCode = 'BURE'
                    clonedPartner.CardName = '-'+clonedPartner.CardName
                    break
                case 'spanc' :
                    clonedPartner.GroupCode = 'SPAN'
                    clonedPartner.CardName = '-'+clonedPartner.CardName
                    break
            }
            apiPost('/api/create-partner', clonedPartner)
                .then(data => {
                    if (data.status === 'error') {
                        dispatch(setMessageInformation({
                            severity: 'error',
                            title: 'Une erreur est survenue',
                            content: data.message
                        }))
                    } else {
                        if(saisieLeadAgent) {
                            navigate('/lead-agent')
                        } else if(saisieWebManager){
                            navigate('/lead-webmanager')
                        } else if(saisieDistriManager){
                            navigate('/lead-distri-manager')
                        } else if(saisieDistriUser) {
                            navigate('/lead-distri-user')
                        } else if(saisieMiseEnService) {
                            switch (openPartner){
                                case 'cutomer' :
                                    customerAdded(data.internalCode)
                                    break;
                                case 'poseur' :
                                    poseurAdded(data.cardCode)
                                    break;
                                case 'be' :
                                    beAdded(data.cardCode)
                                    break;
                                case 'spanc' :
                                    spancAdded(data.cardCode)
                                    break;
                            }
                        } else {
                            setListCustomer(data.data)
                            setFlattenedCustomer(flattenListItem(data.data))
                            const param = {
                                property: 'internalCode',
                                value: data.internalCode,
                                listCustomer: data.data,
                                listModePaiement,
                                listConditionPaiement
                            }
                            dispatch(setDocument(param))
                            setOpenPartner(false)
                        }
                    }
                })
                .finally(() => {
                    setPartnerCreating(false);
                })
        }
    }

    const validatePartner = () => {
        if (saisieMiseEnService && openPartner!=='customer' && (partner.CardName === null || partner.CardName === '')){
            return 'Le champ Nom société est obligatoire'
        } else if (partner.LastName === null || partner.LastName === ''){
            return 'Le champ Nom est obligatoire'
        } else if(partner.FirstName === null || partner.FirstName === ''){
            return 'Le champ Prénom est obligatoire'
        } else if(partner.Email === null || partner.Email === ''){
            return 'Le champ Email est obligatoire'
        } else if(partner.MobilePhone === null || partner.MobilePhone === ''){
            return 'Le champ Mobile est obligatoire'
        } else if(saisieMiseEnService && openPartner==='customer' && (partner.GroupCode === null || partner.GroupCode === '')){
            return 'Le champ Typologie est obligatoire'
        } else if(saisieMiseEnService && openPartner==='customer' && (partner.Typologie === 'PROF' && (partner.CardName === null || partner.CardName === ''))){
            return 'Le champ Société est obligatoire'
        } else if(saisieMiseEnService && openPartner==='customer' && (partner.Typologie === 'ASSO' && (partner.CardName === null || partner.CardName === ''))){
            return 'Le champ Association est obligatoire'
        } else if(partner.StreetB === null || partner.StreetB === ''){
            return 'Le champ Adresse de facturation est obligatoire'
        } else if(partner.ZipCodeB === null || partner.ZipCodeB === ''){
            return 'Le champ Code postal de faturation est obligatoire'
        } else if (partner.CityB === null || partner.CityB === ''){
            return 'Le champ Ville de facturation est obligatoire'
        } else {
            return ''
        }
    }

    const getCityByZipCode = (e) => {
        let AddressType = '';
        if(e.target.value.length === 5){
            setCitySearching(true)
            if(e.target.id === 'ZipCodeB'){
                dispatch(setPartner({
                    property : 'CityB',
                    value : null
                }))
                setListeVilleB([])
                AddressType = 'B'
            } else if(e.target.id === 'ZipCodeS'){
                dispatch(setPartner({
                    property : 'CityS',
                    value : null
                }))
                setListeVilleS([])
                AddressType = 'S'
            }

            let param = {zipCode: e.target.value, withSlpCode: false}

            if(saisieWebManager){
                param.withSlpCode = true
            }

            apiPost('/anonymous-api/get-city',param)
                .then(data => {
                    if(data.status === 'success'){
                        setGeoApiFailed(false)
                        if(AddressType === 'B'){
                            setListeVilleB(data.data)
                        } else if(AddressType === 'S'){
                            setListeVilleS(data.data)
                        }
                    } else {
                        if (data.message === 'api error') {
                            setGeoApiFailed(true)
                        } else {
                            console.log(data.message)
                        }
                    }
                })
                .finally(() => {
                    setCitySearching(false)
                })
        } else {
            if(e.target.id === 'ZipCodeB'){
                dispatch(setPartner({
                    property : 'CityB',
                    value : null
                }))
            } else if(e.target.id === 'ZipCodeS'){
                dispatch(setPartner({
                    property : 'CityS',
                    value : null
                }))
            }
        }
    }

    const location = useLocation()
    const saisieLeadAgent = location.pathname === '/create-lead-agent'
    const saisieWebManager = location.pathname === '/create-lead-webmanager'
    const saisieDistriManager = location.pathname === '/create-lead-distri-manager'
    const saisieDistriUser = location.pathname === '/create-lead-distri-user'
    const saisieDevis = location.pathname === '/create-devis'
    const saisieMiseEnService = location.pathname.includes('create-mise-en-service')
    const drawerForm = saisieDevis || saisieMiseEnService

    const navigate = useNavigate()

    useEffect(() => {
        dispatch(setMessageInformation(null))
        let origine = location.pathname.substring(1)
        let firstSlashPos = origine.indexOf('/')
        if (firstSlashPos !== -1) {
            origine = origine.substring(0,firstSlashPos)
        }
        dispatch(setPartner({
            property: 'Origine',
            value: origine
        }))
        let params = {withSalesPersons: saisieWebManager ? 'Y':'N'}

        apiPost('/api/get-lead-info', params)
            .then(data => {
                if(data.status === 'success'){
                    setListeCivilite(data.civilites)

                    if(data.hasOwnProperty('commerciaux')){
                        setListeCommercial(data.commerciaux)
                    }
                } else {
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: data.message
                    }))
                }
            })
        return (() => {
            dispatch(initPartner())
        })
    }, []);

    let commercial = partner !== null && partner.SlpCode !== null ?
        findFirstBy(objectValuesAlternative(listeCommercial),'SalesEmployeeCode',partner.SlpCode):
        null

    return(
        <Container maxWidth="md">
            <Grid
                container
                sx={{marginTop:2}}
                direction='column'
                spacing={1}
            >
                {
                    ['/devis-form'].includes(location.pathname) ?
                        <Grid item style={{backgroundColor: '#eaeaea'}}>
                            <h1 style={{margin: 0, padding: 10}}>Nouveau client</h1>
                        </Grid>
                    : openPartner==='poseur' ?
                        <h1 style={{margin: '0 0 .5em 0'}}>Nouveau poseur</h1>
                    : openPartner === 'be' ?
                        <h1 style={{margin: '0 0 .5em 0'}}>Nouveau bureau d'étude</h1>
                    : openPartner === 'spanc' ?
                        <h1 style={{margin: '0 0 .5em 0'}}>Nouveau SPANC</h1>
                    : null
                }
                {
                    ['poseur', 'be', 'spanc'].includes(openPartner) ?
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <TextField
                                    fullWidth={true}
                                    label="Nom société"
                                    variant="standard"
                                    id="CardName"
                                    required={true}
                                    value={partner.CardName ? partner.CardName : ''}
                                    onChange={(e) => {
                                        dispatch(setPartner({
                                            property : 'CardName',
                                            value : e.target.value
                                        }))
                                    }}
                                />
                            </Grid>
                        </Grid>
                    : null
                }
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <FormControl required fullWidth>
                            <InputLabel variant="standard">Civilité</InputLabel>
                                <Select
                                    fullWidth={true}
                                    label="Civilité"
                                    variant="standard"
                                    id="Title"
                                    required={true}
                                    value={partner.Title ? partner.Title : ''}
                                    onChange={(e) => {
                                        dispatch(setPartner({
                                            property : 'Title',
                                            value : e.target.value
                                        }))
                                    }}
                                >
                                    <MenuItem value=""></MenuItem>
                                    {
                                        listeCivilite.length > 0 ?
                                            listeCivilite.map((civilite,index) => {
                                                return (
                                                    <MenuItem value={civilite} key={index}>{civilite}</MenuItem>
                                                )
                                            }):
                                            null
                                    }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth={true}
                            label="Nom"
                            variant="standard"
                            id="LastName"
                            required={true}
                            value={partner.LastName ? partner.LastName : ''}
                            onChange={(e) => {
                                dispatch(setPartner({
                                    property : 'LastName',
                                    value : e.target.value
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth={true}
                            label="Prénom"
                            variant="standard"
                            id="FirstName"
                            required={true}
                            value={partner.FirstName ? partner.FirstName : ''}
                            onChange={(e) => {
                                dispatch(setPartner({
                                    property : 'FirstName',
                                    value : e.target.value
                                }))
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth={true}
                            label="Email"
                            variant="standard"
                            id="Email"
                            required={true}
                            value={partner.Email ? partner.Email: ''}
                            onChange={(e) => {
                                dispatch(setPartner({
                                    property: 'Email',
                                    value: e.target.value
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth={true}
                            label="Mobile"
                            variant="standard"
                            id="MobilePhone"
                            required={true}
                            value={partner.MobilePhone ? partner.MobilePhone : ''}
                            onChange={(e) => {
                                dispatch(setPartner({
                                    property: 'MobilePhone',
                                    value: e.target.value
                                }))
                            }}
                        />
                    </Grid>
                </Grid>
                {
                    !['poseur','be','spanc'].includes(openPartner) ?
                        <Grid container spacing={2}>
                            <Grid item xs={partner.GroupCode === 'PROF' || partner.GroupCode === 'ASSO' ? 4: 12}>
                                <FormControl required fullWidth>
                                    <InputLabel variant="standard">Typologie</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        variant="standard"
                                        label="Typologie"
                                        value={partner.GroupCode}
                                        onChange={(e) => {
                                            dispatch(setPartner({
                                                property: 'GroupCode',
                                                value: e.target.value
                                            }))
                                        }}
                                    >
                                        <MenuItem value=""></MenuItem>
                                        <MenuItem value="PART">Particulier</MenuItem>
                                        <MenuItem value="PROF">Professionnel</MenuItem>
                                        <MenuItem value="ASSO">Association</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                partner.GroupCode === 'PROF' || partner.GroupCode === 'ASSO' ?
                                    <Grid item xs={8}>
                                        <TextField
                                            fullWidth={true}
                                            label={partner.GroupCode === 'PROF' ? 'Société': partner.GroupCode === 'ASSO' ? 'Association': ''}
                                            variant="standard"
                                            id="CardName"
                                            value={partner.CardName ? partner.CardName : ''}
                                            onChange={(e) => {
                                                dispatch(setPartner({
                                                    property : 'CardName',
                                                    value : e.target.value
                                                }))
                                            }}
                                        />
                                    </Grid>:
                                    null
                            }
                        </Grid>
                    : null
                }

                <Paper style={{padding: 10, marginTop: 10}} elevation={2}>
                    <Grid item>
                        <Typography variant={'h6'}>
                            {
                                saisieMiseEnService && openPartner === 'customer' ?
                                    'Adresse d\'installation'
                                :
                                    'Adresse de facturation'
                            }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth={true}
                            label="Adresse"
                            variant="standard"
                            id="StreetB"
                            required={true}
                            value={partner.StreetB ? partner.StreetB : ''}
                            onChange={(e) => {
                                dispatch(setPartner({
                                    property : 'StreetB',
                                    value : e.target.value
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth={true}
                            label="Complément d'adresse"
                            variant="standard"
                            id="BlockB"
                            value={partner.BlockB ? partner.BlockB : ''}
                            onChange={(e) => {
                                dispatch(setPartner({
                                    property : 'BlockB',
                                    value : e.target.value
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth={true}
                            label="Code postal"
                            variant="standard"
                            id="ZipCodeB"
                            required={true}
                            value={partner.ZipCodeB ? partner.ZipCodeB : ''}
                            onChange={(e) => {
                                dispatch(setPartner({
                                    property : 'ZipCodeB',
                                    value : e.target.value
                                }))
                                getCityByZipCode(e)
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            freeSolo={geoApiFailed}
                            renderInput={(params) =>
                                <TextField {...params} variant="standard" label="Ville"  />}
                            options={listeVilleB}
                            id="CityB"
                            required={true}
                            value={partner.CityB ? partner.CityB : ''}
                            className={classes.thinTextField}
                            noOptionsText={
                                citySearching ?
                                    'Recherche en cours ...':
                                    'Veuillez renseigner un code postal valide'
                            }
                            onChange={(e, newValue) => {
                                dispatch(setPartner({
                                    property : 'CityB',
                                    value : newValue.id,
                                    listeVilleB
                                }))
                            }}
                        />
                    </Grid>
                </Paper>
                {
                    !saisieMiseEnService ?
                        <Paper style={{padding: 10, marginTop: 10}} elevation={2}>
                            <Grid item>
                                <Grid container>
                                    <Grid item xs={9}>
                                        <Typography variant={'h6'}>
                                            Adresse du chantier
                                        </Typography>
                                        <span style={{fontStyle: 'italic', fontSize: '0.8em'}}>(si différente de l'adresse de facturation)</span>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Switch checked={differenteAddress} onClick={(e) => {
                                            setDifferenteAddress(!differenteAddress)
                                        }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Collapse in={differenteAddress}>
                                <Grid item>
                                    <TextField
                                        fullWidth={true}
                                        label="Adresse"
                                        variant="standard"
                                        id="StreetS"
                                        value={partner.StreetS ? partner.StreetS : ''}
                                        onChange={(e) => {
                                            dispatch(setPartner({
                                                property : 'StreetS',
                                                value : e.target.value
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth={true}
                                        label="Complément d'adresse"
                                        variant="standard"
                                        id="BlockS"
                                        value={partner.BlockS ? partner.BlockS : ''}
                                        onChange={(e) => {
                                            dispatch(setPartner({
                                                property : 'BlockS',
                                                value : e.target.value
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth={true}
                                        label="Code postal"
                                        variant="standard"
                                        id="ZipCodeS"
                                        value={partner.ZipCodeS ? partner.ZipCodeS : ''}
                                        onChange={(e) => {
                                            dispatch(setPartner({
                                                property : 'ZipCodeS',
                                                value : e.target.value
                                            }))
                                            getCityByZipCode(e)
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        freeSolo={geoApiFailed}
                                        renderInput={(params) => <TextField {...params} variant="standard" label="Ville"  />}
                                        options={listeVilleS}
                                        value={partner.CityS ? partner.CityS : ''}
                                        id="CityB"
                                        className={classes.thinTextField}
                                        noOptionsText={
                                            citySearching ?
                                                'Recherche en cours ...':
                                                'Veuillez renseigner un code postal valide'
                                        }
                                        onChange={(e, newValue) => {
                                            dispatch(setPartner({
                                                property : 'CityS',
                                                value : newValue.id,
                                            }))
                                        }}
                                    />
                                </Grid>
                            </Collapse>
                        </Paper>
                    : null
                }


                {
                    saisieWebManager && partner.CityB !== null ?
                        <Grid container style={{marginTop: 20}}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel variant="standard">Commercial</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        variant="standard"
                                        label="Commercial"
                                        value={partner.SlpCode}
                                        onChange={(e) => {
                                            dispatch(setPartner({
                                                property: 'SlpCode',
                                                value: e.target.value
                                            }))
                                        }}
                                    >
                                        <MenuItem value="-1">En fonction de l'adresse</MenuItem>
                                        {
                                            objectValuesAlternative(listeCommercial).map((commercial,index) => {
                                                return(
                                                    <MenuItem value={commercial.SalesEmployeeCode} key={index}>{commercial.SalesEmployeeName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} style={{textAlign: 'center', paddingTop: 10}}>
                                {
                                    commercial !== null && commercial.Phone !== null ?
                                        <a href={"tel:" + commercial.Phone}>
                                            <Chip
                                                variant="outlined"
                                                color="primary"
                                                icon={<FontAwesomeIcon icon={light('mobile')} size="lg" style={{marginLeft: 10}} />}
                                                label={commercial.Phone}
                                                style={{cursor: 'pointer'}} />
                                        </a>:
                                        null
                                }
                            </Grid>
                            <Grid item xs={3} style={{textAlign: 'center', paddingTop: 10}}>
                                {
                                    commercial !== null && commercial.E_Mail !== null ?
                                        <a href={"mailto:" + commercial.E_Mail}>
                                            <Chip
                                                variant="outlined"
                                                color="primary"
                                                icon={<FontAwesomeIcon icon={light('paper-plane-top')} size="lg" style={{marginLeft: 10}} />}
                                                label={commercial.E_Mail}
                                                style={{cursor: 'pointer'}} />
                                        </a>:
                                        null
                                }
                            </Grid>
                        </Grid>:
                        null
                }
                {
                    !saisieMiseEnService ?
                        <Grid container>
                            <Grid
                                item xs={12} md={drawerForm ? 12 : 8}
                                style={{paddingTop: 16}}
                            >
                                <Autocomplete
                                    freeSolo
                                    renderInput={(params) =>
                                        <TextField {...params} variant="standard" label="Objet de la demande" />
                                    }
                                    options={[
                                        {label: 'Demande de Devis/Renseignement'},
                                    ]}
                                    value={partner.Object}
                                    onChange={(e,newValue) => {
                                        dispatch(setPartner({
                                            property: 'Object',
                                            value: newValue.label
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={drawerForm ? 12 : 4}
                                  style={{paddingTop: 20, textAlign: drawerForm ? 'left' : 'right'}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                    <DatePicker
                                        label="Date du chantier"
                                        onChange={e => {
                                            dispatch(setPartner({
                                                property: 'DateChantier',
                                                value: e?.format('YYYY-MM-DD')
                                            }))
                                        }}
                                        value={partner.DateChantier !== '' ? partner.DateChantier: null}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    : null
                }
                <Grid container style={{marginTop: 20}}>
                    <Grid item xs={12}>
                        <TextField
                            multiline={true}
                            fullWidth={true}
                            label="Commentaire"
                            value={document.Comment}
                            onChange={(e) => {
                                dispatch(setPartner({
                                    property: 'Comment',
                                    value: e.target.value
                             }))}
                             }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{marginBottom: 20}}>
                    <Grid item xs={6} style={{marginTop: 20}}>
                        <CancelButton
                            onClick={(e) => {
                                setOpenPartner(false)
                                dispatch(initPartner())
                                if (location.pathname.includes('lead')) {
                                    navigate('/' + location.pathname.substring(8))
                                }
                            }}
                            fullWidth={true}
                            style={{color: 'white'}}
                        >
                            Annuler
                        </CancelButton>
                    </Grid>
                    <Grid item xs={6} style={{marginTop: 20}}>
                        {
                            partnerCreating
                                ?
                                <CancelButton
                                    variant="contained"
                                    fullWidth={true}
                                    disabled
                                >
                                    Création en cours ...
                                </CancelButton>
                                :
                                <ValidateButton
                                    variant="contained"
                                    fullWidth={true}
                                    onClick={(event) => {
                                        createPartner(saisieLeadAgent, saisieWebManager, navigate)
                                    }}
                                >
                                    Créer
                                </ValidateButton>

                        }
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        partner: state.partnerReducer.partner
    }),
)(PartnerForm)
